<template>
  <div :class="['full-page', { 'countdown-end': !isCountdownEnd }]">
    <Load v-if="isLoading" />
    <template v-else>
      <v-row
        v-if="!isCountdownEnd"
        v-bind="{
          justify: 'center',
          'no-gutters': true,
        }"
        class="py-8"
      >
        <v-col cols="12" class="text-center">
          <h2 class="display-m header-start-in">
            <span class="text-pre-order">
              สั่งซื้อล่วงหน้า!
            </span>
            เริ่มอีกใน
          </h2>
        </v-col>

        <v-col cols="auto">
          <!-- <CountDown
            v-if="!isCountdownEnd"
            :timeLeft="timeLeft"
            :isDefault="false"
            :isColon="false"
            :dark="false"
            :endIn="startTime"
            isStudio7
            @end="onEndCountdown"
          /> -->

          <CountDownV2
            v-if="!isCountdownEnd"
            :isDefault="false"
            :isColon="false"
            :dark="false"
            :endIn="startTime"
            isStudio7
            @end="onEndCountdown"
          />
        </v-col>
      </v-row>

      <PreRegisterSection
        :isCountdownEnd="isCountdownEnd"
        @click="toProductDetail"
        @pre-register-form="$refs.preRegisterModal.openModal()"
      />

      <v-row
        v-if="!!innerHtml"
        v-bind="{
          justify: 'center',
          'no-gutters': true,
        }"
        class="items-center"
      >
        <div v-html="innerHtml" class="html-content"></div>
      </v-row>

      <PreRegisterModal ref="preRegisterModal" />
    </template>
  </div>
</template>

<script>
import loadCampaign from '../utils/loadcampaign'
import Load from './Load'
// import CountDown from '@/components/bnn/CountDown'
import isBnn from '@/config/isBnn'
import colorBnn from '@/config/color'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  computed: {
    ...mapState({
      modelLoading: state => state.model.isLoading,
      isLoaded: state => state.model.isLoaded,
      campaign: state => state.campaign.current,
      campaignData: state => state.campaign.data,
      campaignLoading: state => state.campaign.isLoading,
      startTime: state =>
        state?.campaign?.current?.data?.started_at_timestamp ||
        undefined,
      currentTime: state =>
        state?.campaign?.current?.data?.current_datetime_timestamp ||
        undefined,
      startedAt: state => state?.campaign?.current?.data?.started_at,
    }),

    isLoading() {
      return (
        this.campaignLoading ||
        this.modelLoading ||
        this.isLoadingCampaign
      )
    },

    innerHtml() {
      if (this.isCountdownEnd) {
        return this.campaign?.data?.html_after_start
      }
      return this.campaign?.data?.countdown_html
    },

    htmlAfterStart() {
      return !!this.campaign?.data?.html_after_start
    },

    timeLeft() {
      if (this.startTime && this.currentTime) {
        return (
          (this.startTime -
            moment()
              .local()
              .unix()) *
          1000
        )
      }
      return -1
    },

    campaignName() {
      return this.campaign?.data?.name
    },

    countDownHtml() {
      return this.campaign?.data?.countdown_html
    },

    slug() {
      return this.campaign?.data?.slug
    },
  },

  data() {
    return {
      isLoadingCampaign: true,
      isCountdownEnd: false,
      isBnn,
      colorBnn,
      moment,
    }
  },

  watch: {
    timeLeft(val) {
      if (val <= 0 && !this.isLoadingCampaign) {
        this.onEndCountdown()
      }
    },
    isLoadingCampaign(val) {
      if (!val) {
        this.redirectAuto()
      }
    },
  },

  async mounted() {
    const active = await loadCampaign(this.campaign).then(() => {
      this.isLoadingCampaign = false
    })

    if (active) {
      document.body.style.overflowY = ''
      document.querySelector('html').style.overflowY = ''
    }

    if (this.campaign?.data?.redirect_url) {
      window.location.href = this.campaign?.data?.redirect_url
    }
  },

  methods: {
    redirectAuto() {
      this.$nextTick(() => {
        if (!this.htmlAfterStart && this.isCountdownEnd) {
          this.toProductDetail()
        }
      })
    },

    onEndCountdown() {
      this.isCountdownEnd = true

      this.redirectAuto()
    },

    toProductDetail() {
      this.$router
        .push({
          name: 'ProductDetail',
          params: {
            slug: this.slug,
          },
        })
        .catch(() => true)
    },
  },

  components: {
    Load,
    // CountDown,
    PreRegisterSection: () =>
      import('@/components/bnn/PreRegisterSection'),
    PreRegisterModal: () =>
      import('@/components/bnn/PreRegisterModal'),
    CountDownV2: () => import('@/components/bnn/CountDownV2'),
  },
}
</script>

<style lang="stylus" scoped>

.full-page.countdown-end {
  @media screen and (max-width: 600px) {
	margin-top: 177px;
  }
}

.html-content
  width: 100%

.btn-bnn-default
  padding: 0 1.25rem !important
  font-size: 1rem !important

.header-start-in {
    font-size: 18px
    line-height: 20px
    margin-bottom: 8px

    @media screen and (min-width: 768px) {
        font-size: 24px
        line-height: 42px
    }
}
</style>
