<template>
  <div class="loading-screen">
    <v-progress-circular
      :size="100"
      :width="7"
      :color="colorBnn['color-bnn']"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import colorBnn from '@/config/color'

export default {
  data() {
    return {
      colorBnn,
    }
  },
}
</script>

<style lang="stylus" scoped>
.loading-screen
  position fixed
  z-index 10
  top 0
  display flex
  align-items center
  justify-content center
  height 100dvh
  width 100dvw
  background-color rgba(255,255,255,0.6)
</style>
