import isBnn from './isBnn'

const bnn = {
  'color-bnn': '#FFDD00',
  'color-bnn-mid': '#FFDD0073',
  'color-bnn-light': '#FFDD000d',
  'color-coupon': '#FFDD00',
  'color-bnn-opacity-90': '#FFDD0040',
  'color-bnn-gray-light-2': '#f8f8f8',
  'color-bnn-gray-light-3': '#d0d0d0',
}

const studio7 = {
  'color-bnn': '#0071e3',
  'color-bnn-mid': '#0071e373',
  'color-bnn-light': '#0071e30d',
  'color-coupon': '#49A043',
  'color-bnn-opacity-90': '#0071e340',
  'color-bnn-gray-light-2': '#f8f8f8',
  'color-bnn-gray-light-3': '#d0d0d0',
}

export default isBnn ? bnn : studio7
