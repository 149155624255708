import store from '../store'
import router from '@/router'
import axios from '@/config/api'
const campaignType = process.env.VUE_APP_CAMPAIGN_TYPE ?? 'BNN'

export default async campaign => {
  if (campaign?.data?.status === 'active') {
    return true
  }

  const slug = router?.history?.current?.params?.slug || ''

  try {
    const result = !slug
      ? null
      : await axios.get(`/campaign/${campaignType}/${slug}`)

    const val = result?.data || null

    if (val?.data?.id) {
      val.data.isHome = val?.data?.is_delivery_home
      val.data.isShop = val?.data?.is_delivery_store
      val.data.slug = slug
      store.commit(
        'cart/delivery',
        val?.data?.isHome ? 'delivery' : 'store',
      )
      store.dispatch('campaign/update', val)
      store.commit('campaign/updateLoading', false)
    }

    if (val?.data?.status !== 'active') {
      localStorage.removeItem('slug')
    }

    if (
      val?.status === 404 &&
      router.name !== 'Home' &&
      router.name !== 'Thank'
    ) {
      return router
        .push({ name: 'Error', params: { error: 'notfound' } })
        .then(() => false)
        .catch(() => false)
    } else if (
      val?.data?.status === 'incoming' &&
      router.name !== 'Error'
    ) {
      localStorage.setItem('inComingRoute', val?.data?.slug)

      if (
        router?.name === 'CustomerInformation' ||
        router?.history?.current?.name === 'CustomerInformation'
      ) {
        return false
      }

      return router
        .push({
          name: 'CountDown',
          params: {
            slug: val?.data?.slug || this.slug,
          },
        })
        .then(() => false)
        .catch(() => false)
    } else if (
      val?.data?.status === 'expired' &&
      router.name !== 'Error'
    ) {
      return router
        .push({ name: 'Error', params: { error: 'expired' } })
        .then(() => false)
        .catch(() => false)
    } else if (val?.data?.status === 'active') {
      localStorage.setItem('slug', val?.data?.slug)
      return true
    } else {
      localStorage.removeItem('slug')

      return router
        .push({ name: 'Error', params: { error: 'notfound' } })
        .then(() => false)
        .catch(() => false)
    }
  } catch (e) {
    localStorage.removeItem('slug')
    return router
      .push({ name: 'Error', params: { error: 'notfound' } })
      .then(() => false)
      .catch(() => false)
  }
}
