var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['full-page', { 'countdown-end': !_vm.isCountdownEnd }]},[(_vm.isLoading)?_c('Load'):[(!_vm.isCountdownEnd)?_c('v-row',_vm._b({staticClass:"py-8"},'v-row',{
        justify: 'center',
        'no-gutters': true,
      },false),[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h2',{staticClass:"display-m header-start-in"},[_c('span',{staticClass:"text-pre-order"},[_vm._v(" สั่งซื้อล่วงหน้า! ")]),_vm._v(" เริ่มอีกใน ")])]),_c('v-col',{attrs:{"cols":"auto"}},[(!_vm.isCountdownEnd)?_c('CountDownV2',{attrs:{"isDefault":false,"isColon":false,"dark":false,"endIn":_vm.startTime,"isStudio7":""},on:{"end":_vm.onEndCountdown}}):_vm._e()],1)],1):_vm._e(),_c('PreRegisterSection',{attrs:{"isCountdownEnd":_vm.isCountdownEnd},on:{"click":_vm.toProductDetail,"pre-register-form":function($event){return _vm.$refs.preRegisterModal.openModal()}}}),(!!_vm.innerHtml)?_c('v-row',_vm._b({staticClass:"items-center"},'v-row',{
        justify: 'center',
        'no-gutters': true,
      },false),[_c('div',{staticClass:"html-content",domProps:{"innerHTML":_vm._s(_vm.innerHtml)}})]):_vm._e(),_c('PreRegisterModal',{ref:"preRegisterModal"})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }